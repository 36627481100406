@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Mukta');

:root {

}
#root {
  width: 100%;
}
html {
  font-family: 'Mukta', sans-serif; 
  font-size: 62.5%;
}
.App {
  padding-top: 5rem;
  min-height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  /* background-color: #F0F0DF; */
}

.App-header {
  background-color: #2B3A42;
 /* background-color: #031A23;*/
 background-color: #1B404F;
 background-color: #395563;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  color: white;
  padding-left: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 5rem;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  box-shadow: .1rem .1rem .3rem rgba(0,0,0, .3);
}

.App-content,
[role='group'] {
  flex: 1;font-size: 1.8rem;
}
.App-section-outer {
}
.App-section-inner {
  max-width: 96rem;
  margin: 0 auto;
  padding: 6rem 2rem;
 
}
.App-footer {
  min-height: 10rem;
  padding: 2rem;
  text-align: center;
  color: #ccc;
  background-color: #031A23;
  font-size: 3rem;
}
.App-link {
  color: inherit;
}

h1 {
  font-size: 4rem;
  font-weight: bold;
  margin: 2rem;
  text-align: center;

}
p, li {
  font-size: 1.8rem;
  line-height: 1.5;
}
p {
  margin-bottom: 1.8rem;
}
.text-columns-2 {
  column-count: 2;
  column-gap: 40px;
}
@media(max-width: 720px){
  .text-columns-2 {
    column-count: 1;
    column-gap: 0;
  }
  .text-block {
    padding-right: 0;
    text-align: right;
  }
}

.color-milky {
  background-color: #F2EFDC;
}
.brands {
  max-width: 100%;
  width: 32rem;
  margin-bottom: 3rem;
}
.text-columns {
  column-count: 2;
  column-gap: 40px;
  text-align: left;
}
.App-section__title {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 3rem;
}
.App-section-outer p + h2,
.App-section-outer p + p {
  margin-top: 2rem;
} 


