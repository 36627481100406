:root {
  --sizeSmall: 50px;
  --sizeMedium: 100px;
  --sizeLarge: 200px;
  --size: var(--sizeMedium);
}


/*///////////////////////////////////////*/

.grid-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.grid-gallery > * {
  flex: 1;
}
.text-block {
  padding-right: 8rem;
  text-align: right;  
}
.text-block span {
  display: block;
  transform-origin: right center;
  margin: 1rem 0;
  color: #F2EFDC;;
}
.text-block span:nth-child(1){
  font-size: 2.4rem; 
}
.text-block span:nth-child(2){
  font-size: 6rem;
  font-weight: bold;
}
.text-block span:nth-child(3){
  font-size: 4rem;
}
.text-block span:nth-child(4){
  font-size: 2.5rem;
  background-color: #F2EFDC;
  color: #6FA8AF;
  display: inline-block;
  padding: .5rem;
  font-weight: bold;
}
.text-block span:nth-child(5){
  font-size: 5rem;
  font-weight: bold;
}
.text-block span:nth-child(6){
  font-size: 3.5rem;
}

.grid-parent {
  display: grid;
 grid-template-columns: repeat(4, var(--size));
 grid-template-rows: repeat(4, var(--size));
  grid-gap: 20px;
}
.grid-child {
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  position: relative;
  overflow: hidden;
}
/*
.grid-child:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform .3s;
}
.grid-child:hover:before{
  transform: translateY(-100%);
} 
*/
#item1 {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;  
  background: #395563;
}

#item2 {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1; 
  background: #395563;
}
#item3 {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1; 
  background-color: #9EC1A3;
}
#item4 {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1; 
  background-color: #F2EFDC;
}
#item5 {
  grid-column: 2 / span 2;
  grid-row: 3 / span 1; 
  background-color: #385C5C;
}
#item6 {
  grid-column: 1 / span 2;
  grid-row: 4 / span 1; 
  background-color: #A9C7CE;
}
#item7 {
  grid-column: 3 / span 1;
  grid-row: 4 / span 1; 
  background: #A9C7CE;
}

/******************************************************************/



:root {
  --node-color: #333;

}
.nodes {
  line-height: 1.5;
  margin: auto;
}
.nodes h2 {
  text-transform: capitalize;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-left: 3.8rem;
}
.nodes h3 {
  margin-top: 1rem;
  font-size: 2.2rem;
  font-weight: bold;
  color: #F2EFDC;
}
.nodes p {
  font-size: 1.8rem;
  color: #F2EFDC;
}
.nodes li {
  padding: 1.8rem;
  padding-top: 0;
  margin-left: 2rem;
  position: relative;
}
.nodes li:before,
.nodes li:after{
  content: "";
  position: absolute;
  left: .1rem;
  
}
.nodes li:before{
  width: 1rem;
  height: 1rem;
  border: solid .25rem var(--node-color);
  border-radius: 50%;
  transform: translateX(calc(-50% + .08rem));
  box-sizing: border-box;
  top: .5rem;
}
.nodes li:after{
  width: .16rem;
  height: calc(100% - .8rem);
  top: 1rem;
  bottom: 1rem;
  box-sizing: border-box;
  background-color: var(--node-color);
  top: 1.5rem;
}
.nodes li:last-of-type:after{
  background: linear-gradient(var(--node-color), transparent);
}
.nodes .date {
  color: var(--node-color);
  text-transform: uppercase;
  font-size: 1.4rem;
  display: block;
}






.bg-hero {
  background-color: #6FA8AF;
  display: flex;
}
.bg-hero > * {
  min-height: calc(100vh - 5rem);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex: 1;
}





@media (max-width: 720px){
  .bg-hero .grid {
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
  }
  .grid > * {
    flex: 1;
  }
  .bg-hero > .App-section-inner {
   min-height: calc(200vh - 5rem);
   padding: 0;
  }
  .bg-hero .grid.col-50-50 > div {
    min-height: calc(100vh);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2rem;
    margin: 0;
    box-sizing: border-box;
    
    
  }
  .bg-hero .grid.col-50-50 > div:first-child {
    background-color: rgba(0,0,0, .1);
  } 

  .bg-hero .text-block {
    margin: 0;
  }

}
