/*////////// APP NAVIGATION //////////*/
.App-navigation {
  min-height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  overflow-y: auto;
  
} 

/*////////// APP NAVIGATION BUTTON //////////*/

.App-navigation__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background-color: transparent;
  border: none;
  outline: 0;
  position: relative;
  top: 0;
  right: 0;
  z-index: 2;
}
.App-navigation__button svg {
  width: 70%;
  height: 70%;
  fill: #F0F0DF;
}
.App-navigation button:hover {
  cursor: pointer;
}

/*////////// APP NAVIGATION CONTENT //////////*/

.App-navigation__content {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  color: #F0F0DF;
  background-color: #222;
  /*background-color: #2B3A42;*/
  line-height: 1.5;
  min-width: 30rem;
  transition: transform .5s;
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/*/////// APP NAVIGATION HEADER ///////*/

.App-navigation__header {
  padding: 0 3rem;
  font-size: 2rem;
  color: #F0F0DF;
  background-color: rgba(0,0,0, .8);
}
.App-navigation__header > * {
  height: 5rem;
    display: flex;
  align-items: center;
}
/*////////// APP NAVIGATION MENU //////////*/

.App-navigation__menu {
  flex: 1;
  padding-bottom: 3rem;
  
}

#App-navigation__menu--close {
  transform: translateX(100%);
  transition: transform .3s;
}
#App-navigation__menu--open {
  transform: translateX(0%);
  transition: transform .3s;
}

/*////////// APP NAVIGATION MENU ITEMS //////////*/

.App-navigation__menu-item {
  list-style-type: none;
  color: #F0F0DF;
  font-size: 1.6rem;
}
.App-navigation__menu-item a {
  color: inherit;
  text-decoration: none;
  display: block;
  padding: .5rem 1rem;
}
.App-navigation__menu-item + .App-navigation__menu-item {
  border-top: solid .1rem #191919;
}
.App-navigation__menu-item a {
  color: inherit;
  text-decoration: none;
  display: block;
  padding: 1.5rem 6rem;
  position: relative;
}
.App-navigation__menu-item a:before {
  content: "";
  position: absolute;
  left: 3rem;
  top: 50%;
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  background: url(baseline-keyboard-arrow-right.svg) center;
  background-size: 100%;
  transform: translateY(-50%);
  
}
.App-navigation__menu-item a:hover {
  background-color: #137A86;
  background-color: rgba(255,255,255,.1);
}
.page-home #App-navigation__home a,
.page-work #App-navigation__work a,
.page-blog #App-navigation__blog a,
.page-about #App-navigation__about a,
.page-contact #App-navigation__contact a {
  background-color: #137A86;
  background-color: rgba(255,255,255, .1);
}

/*////////// APP NAVIGATION FOOTER //////////*/

.App-navigation__footer {
  background-color: rgba(255,255,255, .1);
  padding: 3rem;
  font-size: 1.4rem;
}
.app-address {
  margin: 1rem 0 0 0;  
  text-align: left;
}


/*////////// APP NAVIGATION OVERLAY //////////*/

.App-navigation__overlay {
  position: fixed;
  background-color: rgba(0,0,0, .3);
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}