.meeting {

}

.meeting .field input[type="text"] {
 padding: .8rem;
 border: solid .1rem #999;
 font-size: 1.8rem;
} 

.meeting .meetings {

}
.meeting .component-title {

}
.meeting .meeting-list {

}

.meeting .meeting-list .meeting-item {

}

.meeting .meeting-list .meeting_item span:hover,
.search-form button:hover {
    cursor: pointer;
}

.search-form {
    display: flex;
    justify-content: center;
}
.search-form .field {
    height: 100%;
}
.search-form button {
    height: 100%;
    display: inline-block;
    background-color: #395563;
    color: #fff;
    border: solid .1rem #999;
    border-left: none;
    padding: 0 1.5rem;
    font-size: 2rem;
}