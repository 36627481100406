.bg-dark {
  background-color: #333;
  color: #ccc;
}
.logo-list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .logo-list ul > * {
    width: 10rem;
    height: 10rem;
    margin: 2rem 1rem;
    background: url(logo-css3.svg) center no-repeat;
    background-size: contain;
  }
  .logo-list li {
    position: relative;
  }
  .logo-list li:hover {
    cursor: pointer;
  }
  .logo-list .logo-list__html {background-image: url(logo-html5.svg);}
  .logo-list .logo-list__css3 {background-image: url(logo-css3.svg);}
  .logo-list .logo-list__js {background-image: url(logo-js.svg);}
  .logo-list .logo-list__react {background-image: url(logo-react.svg);}
  .logo-list .logo-list__node {background-image: url(logo-node.svg);}
  .logo-list .logo-list__adobe {background-image: url(logo-adobe.svg);}
  .logo-list .logo-list__php {background-image: url(logo-php.svg);}
  .logo-list .logo-list__python {background-image: url(logo-python.svg);}
  .logo-list .logo-list__webpack {background-image: url(logo-webpack.svg);}
  .logo-list .logo-list__wordpress {background-image: url(logo-wordpress.svg);}
  .logo-list .logo-list__npm {background-image: url(logo-npm.svg);}
  .logo-list .logo-list__github {background-image: url(logo-github.svg);}
  .logo-list .logo-list__svg {background-image: url(logo-svg.svg);}
  .logo-list .logo-list__firebase {background-image: url(logo-firebase.svg);}
  .logo-list .logo-list__drupal {background-image: url(logo-drupal.svg);}
  .logo-list .logo-list__json {background-image: url(logo-json.svg);}
  .logo-list .logo-list__jquery {background-image: url(logo-jquery.svg);}
  .logo-list .logo-list__mysql {background-image: url(logo-mysql.svg);}
  
  .logo-list__heading {
    font-size: 3rem;
    text-align: center;
    margin: 4rem 0;
    text-align: center;
    line-height: 1.2;
  }
  
  /*/////////// SPEECH BUBBLES //////////*/
  
  .logo-list li:hover:before {
    content: attr(data-speech-bubble);
    position: absolute;
    left: 50%;
    top: -3rem;
    transform: translate(-50%, -100%); 
    background-color: rgba(0,0,0, .8);
    color: #fff;
    padding: 2rem;
    font-size: 1.4rem;
    width: 15rem;
   
    
  }
  .logo-list li:hover:after {
    content: '';
    position: absolute;
    display: block;
    left: 50%;
    top: -1rem;
    transform: translate(-50%, -100%); 
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 2rem solid rgba(0,0,0, .8);
  }
  