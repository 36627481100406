
.App-logo {
    display: flex;
    align-items: center;
}
.App-logo__symbol {
    width: 2.2rem;
    margin-right: 1rem;
}
.App-logo__text {
    color: #F0F0DF;
    font-size: 1.8rem;
}
.App-logo a {
    color: inherit;
    text-decoration: none;
}