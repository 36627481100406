:root {
   --grid-item-size: 1fr;

   --codepen-01: url(codepen-thumbs/codepen-analog-clock.png);
   --codepen-02: url(codepen-thumbs/codepen-animated-beer.png);
   --codepen-03: url(codepen-thumbs/codepen-flexbox-auto-margin.png);
   --codepen-04: url(codepen-thumbs/codepen-grid-layout.png);
   --codepen-05: url(codepen-thumbs/codepen-star-rating.png);

   --codepen-06: url(codepen-thumbs/codepen-animated-switch.png);
   --codepen-07: url(codepen-thumbs/codepen-animated-svg-logo.png);
   --codepen-08: url(codepen-thumbs/codepen-animated-calendar.png);
   --codepen-09: url(codepen-thumbs/codepen-animated-svg.png);
   --codepen-10: url(codepen-thumbs/codepen-css-grid-demo-3.png);

   --codepen-11: url(codepen-thumbs/codepen-css-flexbox-positioning.png);
   --codepen-12: url(codepen-thumbs/codepen-periodic-table.png);
   --codepen-13: url(codepen-thumbs/codepen-responsive-pagination.png);
   --codepen-14: url(codepen-thumbs/codepen-css-flexbox-alignment.png);
   --codepen-15: url(codepen-thumbs/codepen-responsive-filmstrip.png);
   
   --codepen-16: url(codepen-thumbs/codepen-animated-css-gradient.png);
   --codepen-17: url(codepen-thumbs/codepen-screen-aspect-ratios.png);
   --codepen-18: url(codepen-thumbs/codepen-conditional-styling.png);
   --codepen-19: url(codepen-thumbs/codepen-tabs-pure-css.png);
   --codepen-20: url(codepen-thumbs/codepen-conditional-styling.png);
}

.codepen {
   background: #1E1F26 url(logo-codepen-symbol.png) 3rem 3rem;
   background: #fff;
   background-size: 800px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: left;
}
.codepen h2 {
   font-size: 5rem;
   font-weight: bold;
   margin-bottom: 1rem;
   color: #333;
   text-align: center;
}
.codepen p {
   font-size: 1.8rem;
   font-weight: normal;
   color: #333;
}
.codepen__pens {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
}
.codepen__pen {
   width: 15rem;
   height: 15rem;
   background: #666 no-repeat center;
   margin: 2rem;
   background-size: cover;
}

/* GRID STRUCTURE */

.css-grid-group {
   display: flex;
}
.css-grid-group > * {
   flex: 1;
}
.css-grid {
   display: grid;
   grid-template-columns: repeat(12, var(--grid-item-size));
   grid-template-rows: repeat(3, var(--grid-item-size));
   grid-gap: 1rem;
   margin: 0 5rem;
}
.css-grid__child {
   background: no-repeat center;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 3rem;
   font-weight: bold;
   color: #fff;
   position: relative;
   overflow: hidden;
   background-size: contain;
   overflow: hidden;
   text-indent: -500rem;
   border-radius: .2rem;
   transition: all .5s;
}
.css-grid__child a {
   display: block;
   width: 100%;
   height: 100%;
   position: relative;
}

.css-grid__child a:before {
   content: attr(data-tag);
   position: absolute;
   z-index: 3;
   left: 0;
   top: 0;
   display: block;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   color: #fff;
   font-weight: bold;
   font-family: courier new;
   font-size: 1.2rem;
   padding: 1rem;
   box-sizing: border-box;
   opacity: 0;
   transition: opacity .3s .4s;
}
.css-grid__child a:hover:before {
  opacity: 1;
}
.css-grid__child a:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .6);
    transition: opacity .2s ease-in;
    opacity: 0;
}
.css-grid__child a:hover:after {
   opacity: 1;
}



/* GRID ITEMS */

/* Block 1 */

#css-grid__item1 {
   grid-column: 1 / span 2;
   grid-row: 1 / span 2;  
   background-image: var(--codepen-01);
}
#css-grid__item2 {
   grid-column: 3 / span 1;
   grid-row: 1 / span 1; 
   background-image: var(--codepen-02);
}
#css-grid__item3 {
   grid-column: 3 / span 1;
   grid-row: 2 / span 2; 
   background-image: var(--codepen-03);
   background-color: #C5C6BC;
}
#css-grid__item4 {
   grid-column: 2 / span 1;
   grid-row: 3 / span 1;
   background-image: var(--codepen-04); 
}
#css-grid__item5 {
   grid-column: 1 / span 1;
   grid-row: 3 / span 1; 
   background-image: var(--codepen-05);
}


/* Block 2 */

#css-grid__item6 {
   grid-column: 4 / span 2;
   grid-row: 1 / span 1; 
   background-image: var(--codepen-06);
   background-color: #222;
   background-size: cover;
}
#css-grid__item7 {
   grid-column: 6 / span 1;
   grid-row: 1 / span 1; 
   background-image: var(--codepen-07);
}
#css-grid__item8 {
   grid-column: 4 / span 1;
   grid-row: 3 / span 1;
   background-image: var(--codepen-08);
}
#css-grid__item9 {
   grid-column: 5 / span 2;
   grid-row: 2 / span 2; 
   background-image: var(--codepen-09);
}
#css-grid__item10 {
   grid-column: 4 / span 1;
   grid-row: 2 / span 1; 
   background-image: var(--codepen-10);
}


/* Block 3 */

#css-grid__item11 {
   grid-column: 7 / span 1;
   grid-row: 1 / span 1; 
   background-image: var(--codepen-11);
}
#css-grid__item12 {
   grid-column: 8 / span 2;
   grid-row: 1 / span 2; 
   background-image: var(--codepen-12);
}
#css-grid__item13 {
   grid-column: 9 / span 1;
   grid-row: 3 / span 1; 
   background-image: var(--codepen-13);
}
#css-grid__item14 {
   grid-column: 7 / span 2;
   grid-row: 3 / span 1; 
   background-image: var(--codepen-14);
   background-color: #89A4B0;
}
#css-grid__item15 {
   grid-column: 7 / span 1;
   grid-row: 2 / span 1; 
   background-image: var(--codepen-15);
}


/* Block 4 */

#css-grid__item16 {
   grid-column: 10 / span 2;
   grid-row: 1 / span 1; 
   background-image: var(--codepen-16);
   background-color: #FF5527;
   background-size: cover;
}
#css-grid__item17 {
   grid-column: 12 / span 1;
   grid-row: 1 / span 1; 
   background-image: var(--codepen-17);
}
#css-grid__item18 {
   grid-column: 11 / span 2;
   grid-row: 2 / span 2; 
   background-image: var(--codepen-18);
}
#css-grid__item19 {
   grid-column: 10 / span 1;
   grid-row: 3 / span 1; 
   background-image: var(--codepen-19);
}
#css-grid__item20 {
   grid-column: 10 / span 1;
   grid-row: 2 / span 1; 
   background-image: var(--codepen-20);
}


/* Animated gif */

.animated-gif {
    width: 100%;
    height: 100%;
    display: block;
}
.btn-cta {
    display: inline-block;
   text-decoration: none;
    padding: 1.5rem 3rem 1.5rem 5rem;
    color: #fff;
    margin: 5rem 0;
    border-radius: .4rem;
    background: #1E1F26 url(codepen-logo-white.png) 1rem center no-repeat;
    background-size: 3rem;
}


@media(max-width: 1080px){

   .css-grid {
      grid-template-columns: repeat(6, var(--grid-item-size));
      grid-template-rows: repeat(6, var(--grid-item-size));
      margin: 0 3rem;
   }


   /* Block 3 */

   #css-grid__item11 {
      grid-column: 1 / span 1;
      grid-row: 4 / span 1; 
      background-image: var(--codepen-11);
   }
   #css-grid__item12 {
      grid-column: 2 / span 2;
      grid-row: 4 / span 2; 
      background-image: var(--codepen-12);
   }
   #css-grid__item13 {
      grid-column: 3 / span 1;
      grid-row: 6 / span 1; 
      background-image: var(--codepen-13);
   }
   #css-grid__item14 {
      grid-column: 1 / span 2;
      grid-row: 6 / span 1; 
      background-image: var(--codepen-14);
   }
   #css-grid__item15 {
      grid-column: 1 / span 1;
      grid-row: 5 / span 1; 
      background-image: var(--codepen-15);
   }


   /* Block 4 */

   #css-grid__item16 {
      grid-column: 4 / span 2;
      grid-row: 4 / span 1; 
      background-image: var(--codepen-16);
   }
   #css-grid__item17 {
      grid-column: 6 / span 1;
      grid-row: 4 / span 1; 
      background-image: var(--codepen-17);
   }
   #css-grid__item18 {
      grid-column: 5 / span 2;
      grid-row: 5 / span 2; 
      background-image: var(--codepen-18);
   }
   #css-grid__item19 {
      grid-column: 4 / span 1;
      grid-row: 6 / span 1; 
      background-image: var(--codepen-19);
   }
   #css-grid__item20 {
      grid-column: 4 / span 1;
      grid-row: 5 / span 1; 
      background-image: var(--codepen-20);
   }
}


@media(max-width: 768px){

   .css-grid {
      grid-template-columns: repeat(3, var(--grid-item-size));
      grid-template-rows: repeat(12, var(--grid-item-size));
      margin: 0 1rem;
   }


   /* Block 2 */

   #css-grid__item6 {
      grid-column: 1 / span 2;
      grid-row: 4 / span 1; 
      background-image: var(--codepen-06);
   }
   #css-grid__item7 {
      grid-column: 3 / span 1;
      grid-row: 4 / span 1; 
      background-image: var(--codepen-07);
   }
   #css-grid__item8 {
      grid-column: 1 / span 1;
      grid-row: 6 / span 1; 
      background-image: var(--codepen-08); 
   }
   #css-grid__item9 {
      grid-column: 2 / span 2;
      grid-row: 5 / span 2; 
      background-image: var(--codepen-09);
   }
   #css-grid__item10 {
      grid-column: 1 / span 1;
      grid-row: 5 / span 1; 
      background-image: var(--codepen-10);
   }


       /* Block 3 */

   #css-grid__item11 {
      grid-column: 1 / span 1;
      grid-row: 7 / span 1; 
      background-image: var(--codepen-11);
   }
   #css-grid__item12 {
      grid-column: 2 / span 2;
      grid-row: 7 / span 2; 
      background-image: var(--codepen-12);
   }
   #css-grid__item13 {
      grid-column: 3 / span 1;
      grid-row: 9 / span 1; 
      background-image: var(--codepen-13);
   }
   #css-grid__item14 {
      grid-column: 1 / span 2;
      grid-row: 9 / span 1; 
      background-image: var(--codepen-14);
   }
   #css-grid__item15 {
      grid-column: 1 / span 1;
      grid-row: 8 / span 1; 
      background-image: var(--codepen-15);
   }


   /* Block 4 */

   #css-grid__item16 {
      grid-column: 1 / span 2;
      grid-row: 10 / span 1; 
      background-image: var(--codepen-16);
   }
   #css-grid__item17 {
      grid-column: 3 / span 1;
      grid-row: 10 / span 1; 
      background-image: var(--codepen-17);
   }
   #css-grid__item18 {
      grid-column: 2 / span 2;
      grid-row: 11 / span 2; 
      background-image: var(--codepen-18);
   }
   #css-grid__item19 {
      grid-column: 1 / span 1;
      grid-row: 12 / span 1; 
      background-image: var(--codepen-19);
   }
   #css-grid__item20 {
      grid-column: 1 / span 1;
      grid-row: 11 / span 1; 
      background-image: var(--codepen-20);
   }
}