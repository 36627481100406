/*
Absent Horn Grid
Experimental grid system developed by Hans Løgeskov-Eriksen and Torben Colding.

* Responsive (More than flexible. Media queries included)
* Nestable (You can put grids inside grids)
* Based on CSS3 flexible box model (flexbox)
* HTML5 friendly (no specific HTML-elements required)

version 0.9.3.3 beta
Updated: 2017-09-14

---------- DOCUMENTATION ----------

HTML EXAMPLE 1: Two columns

<div class="grid col-50-50">
	<div></div>
	<div></div>
</div>

HTML EXAMPLE 2: Three columns

<div class="grid col-33-33-33">
	<div></div>
	<div></div>
	<div></div>
</div>

HTML EXAMPLE 3: No gutter

<div class="grid col-50-50 no-gutter">
	<div></div>
	<div></div>
</div>


*/

:root {
    --gutter-size: 1;
 }
 
 /* //////////////////// Base Styles //////////////////// */
 
 .grid {
     display: box;
     box-orient: horizontal;
     display: flex;
     flex-direction: row;
     box-sizing: border-box;
 }
 .grid > * > * {margin: calc(1.2rem * var(--gutter-size)) 0;}
 .grid .grid {margin-bottom: 0;}
 
 /* //////////////////// Exp Styles //////////////////// */
 
 .grid:first-child > * > * {margin-top: 0;}
 .grid.no-gutter > *:nth-child(n) > *:nth-child(n) {margin: 0;}
 
 
 /* //////////////////// Grid: 1-2 Columns Width //////////////////// */
 
 .grid.col-100 {display: flex;}
 .grid.col-100 > * {width: 100%;}
  
 .grid.col-50-50 > *:nth-child(1) {width: 50%;}
 .grid.col-50-50 > *:nth-child(2) {width: 50%;}
 
 .grid.col-66-33 > *:nth-child(1) {width: 66.66666%;}
 .grid.col-66-33 > *:nth-child(2) {width: 33.33333%;}
 
 .grid.col-33-66 > *:nth-child(1) {width: 33.33333%;}
 .grid.col-33-66 > *:nth-child(2) {width: 66.66666%;}
 
 .grid.col-75-25 > *:nth-child(1) {width: 75%;}
 .grid.col-75-25 > *:nth-child(2) {width: 25%;}
 
 .grid.col-25-75 > *:nth-child(1) {width: 25%;}
 .grid.col-25-75 > *:nth-child(2) {width: 75%;}
 
 
 /* //////////////////// Grid: 1-2 Columns Margin //////////////////// */
 
 .grid.col-50-50 > *:nth-child(1) > * {margin-right: calc(1.2rem * var(--gutter-size));}
 .grid.col-50-50 > *:nth-child(2) > * {margin-left:  calc(1.2rem * var(--gutter-size));}
 
 .grid.col-66-33 > *:nth-child(1) > * {margin-right: calc(0.8rem * var(--gutter-size));}
 .grid.col-66-33 > *:nth-child(2) > * {margin-left:  calc(1.6rem * var(--gutter-size));}
 
 .grid.col-33-66 > *:nth-child(1) > * {margin-right: calc(1.6rem * var(--gutter-size));}
 .grid.col-33-66 > *:nth-child(2) > * {margin-left:  calc(0.8rem * var(--gutter-size));}
 
 .grid.col-75-25 > *:nth-child(1) > * {margin-right: calc(0.6rem * var(--gutter-size));}
 .grid.col-75-25 > *:nth-child(2) > * {margin-left:  calc(1.8rem * var(--gutter-size));}
 
 .grid.col-25-75 > *:nth-child(1) > * {margin-right: calc(1.8rem * var(--gutter-size));}
 .grid.col-25-75 > *:nth-child(2) > * {margin-left:  calc(0.6rem * var(--gutter-size));}
 
 
 /* //////////////////// Grid: 3 Columns Width //////////////////// */
 
 .grid.col-33-33-33 > *:nth-child(1) {width: 33.33333%;}
 .grid.col-33-33-33 > *:nth-child(2) {width: 33.33333%;}
 .grid.col-33-33-33 > *:nth-child(3) {width: 33.33333%;}
 
 .grid.col-50-25-25 > *:nth-child(1) {width: 50%;}
 .grid.col-50-25-25 > *:nth-child(2) {width: 25%;}
 .grid.col-50-25-25 > *:nth-child(3) {width: 25%;}
 
 .grid.col-25-50-25 > *:nth-child(1) {width: 25%;}
 .grid.col-25-50-25 > *:nth-child(2) {width: 50%;}
 .grid.col-25-50-25 > *:nth-child(3) {width: 25%;}
 
 .grid.col-25-25-50 > *:nth-child(1) {width: 25%;}
 .grid.col-25-25-50 > *:nth-child(2) {width: 25%;}
 .grid.col-25-25-50 > *:nth-child(3) {width: 50%;}
 
 
 /* //////////////////// Grid: 3 Columns Margin //////////////////// */
 
 .grid.col-33-33-33 > *:nth-child(1) > * {margin-right: calc(1.6rem * var(--gutter-size));}
 .grid.col-33-33-33 > *:nth-child(2) > * {margin-right: calc(0.8rem * var(--gutter-size)); margin-left: calc(0.8rem * var(--gutter-size)); }
 .grid.col-33-33-33 > *:nth-child(3) > * {margin-left: calc(1.6rem * var(--gutter-size));}
 
 .grid.col-50-25-25 > *:nth-child(1) > * {margin-right: calc(1.2rem * var(--gutter-size));}
 .grid.col-50-25-25 > *:nth-child(2) > * {margin-left: calc(1.2rem * var(--gutter-size)); margin-right: calc(0.6rem * var(--gutter-size));}
 .grid.col-50-25-25 > *:nth-child(3) > * {margin-left: calc(1.8rem * var(--gutter-size));}
 
 .grid.col-25-50-25 > *:nth-child(1) > * {margin-right: calc(1.8rem * var(--gutter-size));}
 .grid.col-25-50-25 > *:nth-child(2) > * {margin-left: calc(0.6rem * var(--gutter-size)); margin-right: calc(0.6rem * var(--gutter-size));}
 .grid.col-25-50-25 > *:nth-child(3) > * {margin-left: calc(1.8rem * var(--gutter-size));}
 
 .grid.col-25-25-50 > *:nth-child(1) > * {margin-right: calc(1.8rem * var(--gutter-size));}
 .grid.col-25-25-50 > *:nth-child(2) > * {margin-left: calc(0.6rem * var(--gutter-size)); margin-right: calc(1.2rem * var(--gutter-size))}
 .grid.col-25-25-50 > *:nth-child(3) > * {margin-left: calc(1.2rem * var(--gutter-size));}
 
 
 /* //////////////////// Grid: 4 Columns Width //////////////////// */
 
 .grid.col-25-25-25-25 > *:nth-child(1) {width: 25%}
 .grid.col-25-25-25-25 > *:nth-child(2) {width: 25%;}
 .grid.col-25-25-25-25 > *:nth-child(3) {width: 25%;}
 .grid.col-25-25-25-25 > *:nth-child(4) {width: 25%;}
 
 
 /* //////////////////// Grid: 4 Columns Margin //////////////////// */
 
 .grid.col-25-25-25-25 > *:nth-child(1) > * {margin-right: calc(1.8rem * var(--gutter-size));}
 .grid.col-25-25-25-25 > *:nth-child(2) > * {margin-left: calc(0.6rem * var(--gutter-size)); margin-right: calc(1.2rem * var(--gutter-size));}
 .grid.col-25-25-25-25 > *:nth-child(3) > * {margin-left: calc(1.2rem * var(--gutter-size)); margin-right: calc(0.6rem * var(--gutter-size));}
 .grid.col-25-25-25-25 > *:nth-child(4) > * {margin-left: calc(1.8rem * var(--gutter-size));}
 
 
 /* //////////////////// Grid RWD //////////////////// */
 
 @media only screen and (max-width: 960px) {
     
 .grid {flex-wrap: wrap;}
 
 /* //////////////////// Grid 2 Columns //////////////////// */
 
 .grid.col-75-25 > *:nth-child(1) {width: 66.66666%;}
 .grid.col-75-25 > *:nth-child(2) {width: 33.33333%;}
 
 .grid.col-75-25 > *:nth-child(1) > * {margin-right: calc(0.8rem * var(--gutter-size));}
 .grid.col-75-25 > *:nth-child(2) > * {margin-left: calc(1.6rem * var(--gutter-size));}
 
 .grid.col-25-75 > *:nth-child(1) {width: 33.33333%;}
 .grid.col-25-75 > *:nth-child(2) {width: 66.66666%;}
 
 .grid.col-25-75 > *:nth-child(1) > * {margin-right: calc(1.6rem * var(--gutter-size));}
 .grid.col-25-75 > *:nth-child(2) > * {margin-left: calc(0.8rem * var(--gutter-size));}
 
             
 .grid.col-25-50-25 > *:nth-child(1) {width: 33.33333%;}
 .grid.col-25-50-25 > *:nth-child(2) {width: 33.33333%;}
 .grid.col-25-50-25 > *:nth-child(3) {width: 33.33333%;}
 
 .grid.col-25-50-25 > *:nth-child(1) > * {margin-right: calc(1.6rem * var(--gutter-size));}
 .grid.col-25-50-25 > *:nth-child(2) > * {margin-left: calc(0.8rem * var(--gutter-size)); margin-right: calc(0.8rem * var(--gutter-size));}
 .grid.col-25-50-25 > *:nth-child(3) > * {margin-left: calc(1.6rem * var(--gutter-size));}
 
 .grid.col-25-25-50 > *:nth-child(1) {width: 25%;}
 .grid.col-25-25-50 > *:nth-child(2) {width: 25%;}
 .grid.col-25-25-50 > *:nth-child(3) {width: 50%;}
 
 .grid.col-25-25-50 > *:nth-child(1) > * {margin-right: calc(1.6rem * var(--gutter-size));}
 .grid.col-25-25-50 > *:nth-child(2) > * {margin-left: calc(0.8rem * var(--gutter-size)); margin-right: calc(0.8rem * var(--gutter-size));}
 .grid.col-25-25-50 > *:nth-child(3) > * {margin-left: calc(1.6rem * var(--gutter-size))}
 
 /* //////////////////// Grid 3 columns //////////////////// */
 
 .grid.col-25-25-25-25 > *:nth-child(1) {width: 50%;}
 .grid.col-25-25-25-25 > *:nth-child(2) {width: 50%;}
 .grid.col-25-25-25-25 > *:nth-child(3) {width: 50%;}
 .grid.col-25-25-25-25 > *:nth-child(4) {width: 50%;}
 
 .grid.col-25-25-25-25 > *:nth-child(1) > * {margin-right: calc(1.2rem * var(--gutter-size));}
 .grid.col-25-25-25-25 > *:nth-child(2) > * {margin-left: calc(1.2rem * var(--gutter-size)); margin-right: 0;}
 .grid.col-25-25-25-25 > *:nth-child(3) > * {margin-left: 0; margin-right: calc(1.2rem * var(--gutter-size));}
 .grid.col-25-25-25-25 > *:nth-child(4) > * {margin-left: calc(1.2rem * var(--gutter-size));}
     
 }
 @media only screen and (max-width: 768px) {
 
 /* //////////////////// Grid 2 Columns //////////////////// */
 
 .grid.col-66-33 > *:nth-child(1) {width: 50%;}
 .grid.col-66-33 > *:nth-child(2) {width: 50%;}
 
 .grid.col-66-33 > *:nth-child(1) > * {margin-right: calc(1.2rem * var(--gutter-size));}
 .grid.col-66-33 > *:nth-child(2) > * {margin-left: calc(1.2rem * var(--gutter-size));}
 
 .grid.col-33-66 > *:nth-child(1) {width: 50%;}
 .grid.col-33-66 > *:nth-child(2) {width: 50%;}
 
 .grid.col-33-66 > *:nth-child(1) > * {margin-right: calc(1.2rem * var(--gutter-size));}
 .grid.col-33-66 > *:nth-child(2) > * {margin-left: calc(1.2rem * var(--gutter-size));}
 
 .grid.col-75-25 > *:nth-child(1) {width: 50%;}
 .grid.col-75-25 > *:nth-child(2) {width: 50%;}
 
 .grid.col-75-25 > *:nth-child(1) > * {margin-right: calc(1.2rem * var(--gutter-size));}
 .grid.col-75-25 > *:nth-child(2) > * {margin-left: calc(1.2rem * var(--gutter-size));}
 
 .grid.col-25-75 > *:nth-child(1) {width: 50%;}
 .grid.col-25-75 > *:nth-child(2) {width: 50%;}
 
 .grid.col-25-75 > *:nth-child(1) > * {margin-right: calc(1.2rem * var(--gutter-size));}
 .grid.col-25-75 > *:nth-child(2) > * {margin-left: calc(1.2rem * var(--gutter-size));}
 
 /* //////////////////// Grid 3 Columns //////////////////// */
 
 .grid.col-50-25-25 > *:nth-child(1) {width: 100%;}
 .grid.col-50-25-25 > *:nth-child(2) {width: 50%;}
 .grid.col-50-25-25 > *:nth-child(3) {width: 50%;}
 
 .grid.col-50-25-25 > *:nth-child(1) > * {margin-right: 0;}
 .grid.col-50-25-25 > *:nth-child(2) > * {margin-left: 0; margin-right: calc(1.2rem * var(--gutter-size));}
 .grid.col-50-25-25 > *:nth-child(3) > * {margin-left: calc(1.2rem * var(--gutter-size));}
         
 }
 @media only screen and (max-width: 720px) {
     
 .grid:first-child > * > * {margin-top: calc(1.2rem * var(--gutter-size));}
 .grid:first-child > *:first-child > * {margin-top: 0;}
 
 .grid:last-child > * > * {margin-bottom: calc(1.2rem * var(--gutter-size));}
 .grid:last-child > *:last-child > * { margin-bottom: 0;}
 
 /* //////////////////// Grids 2 Columns //////////////////// */
 
 .grid.col-50-50 > *:nth-child(1) {width: 100%; margin-bottom: 3rem}
 .grid.col-50-50 > *:nth-child(2) {width: 100%;}
 
 .grid.col-50-50 > *:nth-child(1) > * {margin-right: 0; }
 .grid.col-50-50 > *:nth-child(2) > * {margin-left: 0;}
 
 .grid.col-66-33 > *:nth-child(1) {width: 100%;}
 .grid.col-66-33 > *:nth-child(2) {width: 100%;}
 
 .grid.col-66-33 > *:nth-child(1) > * {margin-right: 0;}
 .grid.col-66-33 > *:nth-child(2) > * {margin-left: 0;}
 
 .grid.col-33-66 > *:nth-child(1) {width: 100%;}
 .grid.col-33-66 > *:nth-child(2) {width: 100%; }
 
 .grid.col-33-66 > *:nth-child(1) > * {margin-right: 0;}
 .grid.col-33-66 > *:nth-child(2) > * {margin-left: 0;}
 
 .grid.col-75-25 > *:nth-child(1) {width: 100%;}
 .grid.col-75-25 > *:nth-child(2) {width: 100%;}
 
 .grid.col-75-25 > *:nth-child(1) > * {margin-right: 0;}
 .grid.col-75-25 > *:nth-child(2) > * {margin-left: 0;}
 
 .grid.col-25-75 > *:nth-child(1) {width: 100%;}
 .grid.col-25-75 > *:nth-child(2) {width: 100%;}
 
 .grid.col-25-75 > *:nth-child(1) > * {margin-right: 0;}
 .grid.col-25-75 > *:nth-child(2) > * {margin-left: 0;}
             
 /* //////////////////// Grids 3 Columns //////////////////// */
 
 .grid.col-33-33-33 > *:nth-child(1),
 .grid.col-33-33-33 > *:nth-child(2),
 .grid.col-33-33-33 > *:nth-child(3) {width: 100%;}
 
 .grid.col-33-33-33 > *:nth-child(1) > *,
 .grid.col-33-33-33 > *:nth-child(2) > *,
 .grid.col-33-33-33 > *:nth-child(3) > * {margin-right: 0; margin-left: 0;}
 
 .grid.col-50-25-25 > *:nth-child(1),
 .grid.col-50-25-25 > *:nth-child(2),
 .grid.col-50-25-25 > *:nth-child(3) {width: 100%;}
 
 .grid.col-50-25-25 > *:nth-child(1) > * {margin-right: 0;}
 .grid.col-50-25-25 > *:nth-child(2) > * {margin-left: 0; margin-right: 0;}
 .grid.col-50-25-25 > *:nth-child(3) > * {margin-left: 0;}
 
 .grid.col-25-50-25 > *:nth-child(1),
 .grid.col-25-50-25 > *:nth-child(2),
 .grid.col-25-50-25 > *:nth-child(3) {width: 100%;}
 
 .grid.col-25-50-25 > *:nth-child(1) > * {margin-right: 0;}
 .grid.col-25-50-25 > *:nth-child(2) > * {margin-left: 0; margin-right: 0;}
 .grid.col-25-50-25 > *:nth-child(3) > * {margin-left: 0;}
 
 .grid.col-25-25-50 > *:nth-child(1),
 .grid.col-25-25-50 > *:nth-child(2),
 .grid.col-25-25-50 > *:nth-child(3) {width: 100%;}
 
 .grid.col-25-25-50 > *:nth-child(1) > * {margin-right: 0;}
 .grid.col-25-25-50 > *:nth-child(2) > * {margin-left: 0; margin-right: 0;}
 .grid.col-25-25-50 > *:nth-child(3) > * {margin-left: 0;}
 
 /* //////////////////// Grids 4 Columns //////////////////// */
 
 .grid.col-25-25-25-25 > *:nth-child(1),
 .grid.col-25-25-25-25 > *:nth-child(2),
 .grid.col-25-25-25-25 > *:nth-child(3),
 .grid.col-25-25-25-25 > *:nth-child(4) {width: 100%;}
 
 .grid.col-25-25-25-25 > *:nth-child(1) > * {margin-right: 0;}
 .grid.col-25-25-25-25 > *:nth-child(2) > * {margin-left: 0; margin-right: 0;}
 .grid.col-25-25-25-25 > *:nth-child(3) > * {margin-left: 0; margin-right: 0;}
 .grid.col-25-25-25-25 > *:nth-child(4) > * {margin-left: 0;}
 }